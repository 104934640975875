//import 'bootstrap/dist/css/bootstrap.css';
//import 'react-app-polyfill/ie11';
//import 'react-app-polyfill/stable';
import "./styles/theme.scss";
import "./styles/app.scss";

import React, { useEffect, useRef } from "react";
import { Provider, useSelector } from "react-redux";
import { store, routerHistory, dispatch } from "./redux/redux-config";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router";
import TopBar from "./components/top-bar.jsx";
import SideBar from "./components/side-bar.jsx";
import LoginPage from "./pages/LoginPage.jsx";
import Help from "./pages/Help.jsx";
import MyAlerts from "./pages/MyAlerts.jsx";
import SendAlert from "./pages/SendAlert.jsx";
import AlertDetails from "./pages/AlertDetails.jsx";
import Successful from "./pages/Successful.jsx";
import { MobileWidth, Path } from "./utilities/constants";
import { ToastContainer } from "./components/toast";
import PeoplePage from "./pages/People";
import AddPersonPage from "./pages/AddPerson";
import GroupsPage from "./pages/Groups";
import Templates from "./pages/Templates";
import initPendo from "./utilities/pendo";
import GroupMembers from "./pages/GroupMembers";
import useAutoLogout from "./utilities/auto-logout.js";
import { showToast } from "./components/toast";
import actions from "./actions/actions";
import Dashboard from "./pages/Dashboard.jsx";

const ReportsPage = React.lazy(() => import("./pages/Reports.jsx"));
const LazyReportsPage = () => (
  <React.Suspense
    fallback={
      <div className="loading-page">
        <i className="fa fa-spinner fa-spin"></i>
      </div>
    }
  >
    <ReportsPage />
  </React.Suspense>
);
const SettingsPage = React.lazy(() => import("./pages/Settings.jsx"));
const LazySettingsPage = () => (
  <React.Suspense
    fallback={
      <div className="loading-page">
        <i className="fa fa-spinner fa-spin"></i>
      </div>
    }
  >
    <SettingsPage />
  </React.Suspense>
);

export default function App() {
  window.hgsVersion = "2.2.0";

  return (
    <Provider store={store}>
      <ConnectedRouter history={routerHistory}>
        <PageContent />
      </ConnectedRouter>
    </Provider>
  );
}

function PageContent() {
  let isSidebarExpanded = useSelector((store) => store.page.sidebarExpanded);
  let isMobile = useSelector((store) => store.page.isMobile);
  let mobileRef = useRef(isMobile);
  let user = useSelector((store) => store.user);
  useAutoLogout(actions.user.logout, 300000, 30000, user.loaded);

  mobileRef.current = isMobile;

  if (user.loaded) {
    const pendoAccount = {
      id: "CM-" + user.customerId,
      name: user.customerName,
      //Keep for future migration
      //type: user.account_type_name,
      //typeId: user.AccountTypeID,
      //address: user.ChurchAddress,
      //city: user.ChurchCity,
      //state: user.ChurchState,
      //zipCode: user.ChurchZip,
      //country: user.ChurchCountry,
      //creationDate: user.agreement_start_date,
      //trialExpirationDate: user.FreeTrialExpiry,
      //plan: user.callPlanName,
      //product: user.product_name,
      //productId: user.product_id
    };
    const pendoVisitor = {
      id: "CM-" + user.userId,
      fullName: user.firstName + " " + user.lastName,
      firstName: user.firstName,
      lastName: user.lastName,
      //Keep for future migration
      //userName: user.userName,
      //role: user.user_type_id,
      //city: user.city,
      //state: user.state,
      //zipCode: user.zip
    };
    initPendo(pendoAccount, pendoVisitor);
  }

  useEffect(() => {
    function resized() {
      let isMobile = window.innerWidth <= MobileWidth;
      if (isMobile !== mobileRef.current)
        dispatch("IS_MOBILE_CHANGED", isMobile);
    }
    window.addEventListener("resize", resized);
    return () => window.removeEventListener("resize", resized);
  }, []);

  let hasLoginToken = useSelector((store) => store.user.token);
  if (!hasLoginToken) {
    routerHistory.replace("/");
    return <LoginPage />;
  }

  return (
    <>
      <TopBar />
      <SideBar />
      <div
        className={
          "page-content" +
          (isSidebarExpanded ? " expanded-side-bar" : "") +
          (isMobile ? " mobile" : "")
        }
      >
        <ConnectedRouter history={routerHistory}>
          <Switch>
            <Route path={Path.Send} component={SendAlert} />
            <Route path={Path.Templates} component={Templates} />
            <Route path={Path.Sent} component={Successful} />
            <Route path={Path.Track} component={MyAlerts} />
            <Route path={Path.AlertDetails} component={AlertDetails} />
            <Route path={Path.AddPerson} component={AddPersonPage} />
            <Route path={Path.People} component={PeoplePage} />
            <Route path={Path.Groups} component={GroupsPage} />
            <Route path={Path.GroupMembers} component={GroupMembers} />
            <Route path={Path.Reports} component={LazyReportsPage} />
            <Route path={Path.Settings} component={LazySettingsPage} />
            <Route path={Path.Help} component={Help} />
            <Route path={Path.Dashboard} component={Dashboard} />
          </Switch>
        </ConnectedRouter>
      </div>
      <ToastContainer />
    </>
  );
}
