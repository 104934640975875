import React, { useState } from "react";
import ProfileCard from "../components/profile-card";
import PageLayout from "../components/page-layout";
import RecentMessages from "./RecentMessages";
import "../styles/dashboard.scss";
import GroupMembership from "./GroupMembership";
import EditProfile from "./EditProfile";
import AddContactPopup from "./AddContactPopup";
import { SettingsApi } from "../actions/settings-action";
import { useOnFirstLoad } from "../utilities/hooks";
import { isMobile } from "../utilities/constants";
import BottomNavBar from "./BottomNavBar";
import MessageDropdown from "./MessageDropdown";

export default function Dashboard() {
  const [isEditing, setIsEditing] = useState(false);
  const [isGroupEditing, setIsGroupEditing] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [profilePicEdit, setProfilePicEdit] = useState(false);
  const [languageResponse, setLanguageResponse] = useState([]);
  const [withVoiceId, setWithVoiceId] = useState([]);
  const [profileCardKey, setProfileCardKey] = useState(0);

  const handleGroupEditToggle = () => {
    setIsGroupEditing(!isGroupEditing);
  };
  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };
  const handleProfileEdit = () => {
    setDisplayModal(!displayModal);
    setProfilePicEdit(!profilePicEdit);
  };
  const handleAddContact = () => {
    setContactModal(!contactModal);
    setAddModalOpen(!isAddModalOpen);
  };
  const handleProfileCardKey = () => {
    setProfileCardKey((prevKey) => prevKey + 1);
  };
  useOnFirstLoad(() => {
    async function getPreferredLanguage() {
      let response = await SettingsApi.getContactLanguage();
      if (response.success) {
        let languageResponse = response.languages;
        setLanguageResponse(languageResponse);

        let withVoiceId = languageResponse.filter((item) =>
          item.hasOwnProperty("voiceId")
        );
        setWithVoiceId(withVoiceId);
      }
    }

    getPreferredLanguage();
  });

  return (
    <>
      {displayModal && (
        <EditProfile
          displayModal={displayModal}
          onCloseClick={handleProfileEdit}
          onSucessfullAdd={handleProfileCardKey}
        ></EditProfile>
      )}
      {contactModal && (
        <AddContactPopup
          contactModal={contactModal}
          onClose={handleAddContact}
          textPreferredLanguage={languageResponse}
          voicePreferredLanguage={withVoiceId}
          onSucessfullAdd={handleProfileCardKey}
        ></AddContactPopup>
      )}
      <PageLayout
        title="My Dashboard"
        buttonBar={
          <div className="mobile-title">
            {isMobile() && (
              <div className="mobile-title-progress-icon">
                <i className="fa fa-bars-progress "></i>
              </div>
            )}
            <MessageDropdown isEditing={isEditing} />
          </div>
        }
      ></PageLayout>

      <div className={`my-dashboard ${isEditing && "overlay"}`}>
        <ProfileCard
          key={profileCardKey}
          onEditClick={handleEditToggle}
          isEditing={isEditing}
          isGroupEditing={isGroupEditing}
          onProfileEdit={handleProfileEdit}
          isProfilePicEdit={profilePicEdit}
          onAddContact={handleAddContact}
          isAddModalOpen={isAddModalOpen}
        />
        {!isMobile() && (
          <div className="sidebard-scrollbar">
            <RecentMessages
              isEditing={isEditing}
              isGroupEditing={isGroupEditing}
              isProfilePicEdit={profilePicEdit}
              isAddModalOpen={isAddModalOpen}
            />
            <GroupMembership
              isEditing={isEditing}
              onEditClick={handleGroupEditToggle}
              isProfilePicEdit={profilePicEdit}
              isAddModalOpen={isAddModalOpen}
            ></GroupMembership>
          </div>
        )}
        {isMobile() && <BottomNavBar />}
      </div>
    </>
  );
}
