export const AlertStatus = {
  Error: -1,
  All: 0,
  Sent: 1,
  Scheduled: 2,
  Draft: 3,
};

export const AlertType = {
  All: 0,
  Voice: 1,
  Email: 2,
  Text: 3,
  VoiceIVR: 4,
  RSS: 7,
  EmergencyText: -1,
};

export const MobileWidth = 767;
export const AnimationLength = 250;

export function isMobile() {
  return window.innerWidth < MobileWidth;
}

export const Path = {
  /* Messages */
  // Messages: "/",
  Dashboard: "/",
  UserDashboard: "/contact",
  /* Alerts */
  Track: "/track",
  TrackSent: "/track/sent",
  TrackScheduled: "/track/scheduled",
  TrackDrafts: "/track/drafts",
  Archived: "/track/archived",
  SendQuickLaunch: "/send/quick",
  Templates: "/templates",
  Send: "/send",
  SendEmergency: "/send/emergency",
  SendQuickLaunch: "/send/quick",
  Sent: "/successful",
  AlertDetails: "/alert",
  /* People */
  People: "/people",
  AddPerson: "/people/add",
  /* Groups */
  Groups: "/groups",
  GroupMembers: "/group-members/:id",
  /* Admin Reports */
  Reports: "/reports",
  ReportCommuication: "/reports/communication",
  ReportRecent: "/reports/recent",
  ReportInvalidContacts: "/reports/invalidcontacts",
  ReportOptOut: "/reports/optout",
  ReportLocation: "/reports/location",
  /* User Profile */
  Profile: "/settings/profile",
  MyAccount: "/settings/myaccount",
  /* Settings */
  Settings: "/settings",
  Admin: "/admin",
  AdminInvalidContacts: "/admin/invalidcontacts",
  AdminInvalidPhone: "/admin/invalidphone",
  AdminRecent: "/admin/recent",
  AdminLogins: "/admin/logins",
  AdminEmailOptOut: "/admin/optout/email",
  AdminSmsOptOut: "/admin/optout/sms",
  AdminAttendance: "/admin/attendance",
  AdminLunch: "/admin/lunch",
  AdminLocation: "/admin/location",
  AdminCommuication: "/admin/communication",
  AccountDetails: "/settings/account",
  AccountSummary: "/settings/summary",
  AccountPlan: "/settings/plan",
  AccountPayment: "/settings/payment",
  SettingsPassword: "/settings/password",
  SettingsUsername: "/settings/username",
  SettingsVoice: "/settings/voice",
  SettingsSms: "/settings/sms",
  SettingsLanguage: "/settings/language",
  SettingsContact: "/settings/contact",
  SettingsPhoneCode: "/settings/phone-code",
  SettingsMessageHistory: "/settings/message-history",
  SettingsAddress: "/settings/address",
  SettingsGroups: "/settings/groups",
  /* Help */
  Help: "/help",
};

export const PeopleTab = {
  All: 0,
  Employee: 1,
  Managers: 3,
  Admin: 4,
};

export const UserRole = {
  Member: 1,
  Staff: 3,
  Admin: 4,
};

export const USStates = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];
