import { settings } from "../api/settings-api";
import { appState } from "../redux/redux-config";

export const SettingsApi = {
  getContactDetails: async function () {
    let token = appState().user.token;
    let result = await settings.getContactDetails(token);
    return result;
  },
  getVoiceDetails: async function () {
    let token = appState().user.token;
    let result = await settings.getVoiceDetails(token);
    return result;
  },
  getVoiceOptions: async function () {
    let token = appState().user.token;
    let result = await settings.getVoiceOptions(token);
    return result;
  },
  updateVoice: async function (id) {
    let token = appState().user.token;
    let result = await settings.updateVoice(token, id);
    return result;
  },
  getContactLanguage: async function () {
    let token = appState().user.token;
    let result = await settings.getContactLanguage(token);
    return result;
  },

  addContact: async function (payload) {
    let token = appState().user.token;
    let result = await settings.addContact(token, payload);
    return result;
  },
  updateContact: async function (payload) {
    let token = appState().user.token;
    let result = await settings.updateContact(token, payload);
    return result;
  },
  deleteContact: async function (contactId) {
    let token = appState().user.token;
    let result = await settings.deleteContact(token, contactId);
    return result;
  },
  sampleFile: async function (id) {
    let token = appState().user.token;
    let result = await settings.sampleFile(token, id);
    return result;
  },
  getMessageHistory: async function (alertType, sort) {
    let token = appState().user.token;
    let result = await settings.getMessageHistory(token, alertType, sort);
    return result;
  },
  saveSmsPreference: async function (smsEmail, isEmailChecked) {
    let token = appState().user.token;
    let result = await settings.saveSmsPreferences(
      token,
      smsEmail,
      isEmailChecked
    );
    return result;
  },
  savePersonAddress: async function (reqData) {
    let token = appState().user.token;
    let result = await settings.savePersonAddress(token, reqData);
    return result;
  },
  getPersonAddress: async function () {
    let token = appState().user.token;
    let result = await settings.getPersonAddress(token);
    return result;
  },
  getUserGroups: async function () {
    let token = appState().user.token;
    let result = await settings.getUserGroups(token);
    return result;
  },
  addUserGroups: async function (payload) {
    let token = appState().user.token;
    let result = await settings.addUserGroups(token, payload);
    return result;
  },
  deleteUserGroups: async function (payload) {
    let token = appState().user.token;
    let result = await settings.deleteUserGroups(token, payload);
    return result;
  },
};
