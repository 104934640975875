import React, { useState } from "react";
import "../styles/add-contact-popup.scss";
import CircularProgress from "../actions/CircularProgress";
import { useUpdateState } from "../utilities/utilities";
import { GuiButton } from "../components/global-ui/gui-button";
import { GuiSelect } from "../components/global-ui/gui-select";
import { GuiTextbox } from "../components/global-ui/gui-textbox";
import { GuiRadio } from "../components/global-ui/gui-radio";

import { useOnFirstLoad } from "../utilities/hooks";
import { SettingsApi } from "../actions/settings-action";
import { GuiCheckbox } from "../components/global-ui/gui-checkbox";
import { showToast } from "../components/toast";
import { USStates } from "../utilities/constants";

const AddContactPopup = ({
  contactModal,
  onClose,
  textPreferredLanguage,
  voicePreferredLanguage,
  onSucessfullAdd,
}) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let states = USStates;
  let [state, updateState] = useUpdateState({
    loading: true,
    isAddDisabled: false,
    contactType: 1,
    typeOptions: [
      { value: 1, label: "Home Phone" },
      // { value: 2, label: "Work Phone" },
      { value: 3, label: "Cell Phone" },
      { value: 4, label: "Email" },
      { value: 5, label: "Address" },
    ],
    phoneNumber: "",
    ext: "",
    isValid: true,
    voiceOptions: [],
    textOptions: [],
    enableTextMsg: true,
    enableVoiceMsg: true,
  });
  let [personAddress, updatePersonAddress] = useUpdateState({
    address: "",
    city: "",
    state: "",
    zip: "",
    comment: "",
  });

  const [fieldErrors, setFieldErrors] = useState({
    address: false,
    city: false,
    zip: false,
    state: false,
    comment: false,
  });
  let [errors, setErrors] = useState({
    phoneNumber: "",
    email: "",
  });
  useOnFirstLoad(() => {
    // textPreferredLanguage.length > 0 &&
    //   voicePreferredLanguage.length > 0 &&
    updateState({ loading: false });
    SettingsApi.getPersonAddress().then((result) => {
      if (!result.success) return;
      updatePersonAddress({
        address: result.address,
        city: result.city,
        state: result.state,
        zip: result.zip,
        comment: result.address_comment,
      });
    });
    if (voicePreferredLanguage.length > 0 || textPreferredLanguage.length > 0) {
      const voiceLanguageOptions = voicePreferredLanguage.map((obj) => ({
        label: obj.name,
        value: obj.voiceId,
      }));
      const textLanguageOptions = textPreferredLanguage.map((obj) => ({
        label: obj.name,
        value: obj.textCode,
      }));
      updateState({
        textOptions: textLanguageOptions,
        voiceOptions: voiceLanguageOptions,
        // loading: false,
      });
    }
  });

  const handleChange = (name, value) => {
    updateState(name, value);
    if (name === "phoneNumber") {
      setErrors({ phoneNumber: "" });
    }
  };

  const handleRadioChange = (name, value) => {
    updateState({ ...state, [name]: value === "true" });
  };

  const validateFields = () => {
    const result =
      state.contactType === 4
        ? validateEmail(state.email)
        : validateAndFormatPhoneNumber(state.phoneNumber);

    if (result.isValid) {
      state.contactType !== 4 &&
        updateState({ phoneNumber: result.formattedNumber });
      return true;
    } else {
      state.contactType === 4
        ? setErrors({ email: result.message })
        : setErrors({ phoneNumber: result.message });
      return false;
    }
  };
  const onUpdateAddress = async () => {
    updateState({ isAddDisabled: true });
    var reqData = {
      address: personAddress.address,
      city: personAddress.city,
      state: personAddress.state,
      zip: personAddress.zip,
      address_comment: personAddress.comment,
    };
    const result = await SettingsApi.savePersonAddress(reqData);
    if (result.success) {
      showToast("success", "Address Added");
      onSucessfullAdd();
    }
    onClose();
    return result;
  };

  const handleAddressChange = (name, value) => {
    updatePersonAddress({ [name]: value });

    const zipCodeRegex = /^\d{5}(-\d{4})?$/;
    const isZipValid = name === "zip" && zipCodeRegex.test(value);

    if (name !== "comment") {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [name]:
          name === "zip"
            ? value === ""
              ? "Required Field"
              : !isZipValid
              ? "Invalid ZIP format"
              : ""
            : value === ""
            ? "Required Field"
            : "",
      }));

      const isAnyFieldInvalid =
        name === "zip" ? value === "" || !isZipValid : value === "";

      updateState({ isAddDisabled: isAnyFieldInvalid });
    }
  };

  const onAddClick = async () => {
    if (!validateFields()) {
      return;
    }

    updateState({ isAddDisabled: true });

    const payload = {
      Phone: state.contactType === 4 ? state.email : state.phoneNumber,
      Ext: state.ext,
      ContactType: state.contactType,
      NoTextMessage: state.contactType === 3 ? !state.enableTextMsg : false,
      TextMessageOnly: !state.enableVoiceMsg,
      InvalidFlag: state.isValid === true ? 0 : 1,
      VoiceLanguageId:
        state.enableVoiceMsg === true
          ? state.voiceType === "Select"
            ? null
            : state.voiceType
          : null,
      TextLanguageCode:
        state.enableTextMsg === true
          ? state.textType === "Select"
            ? ""
            : state.textType
          : "",
    };
    let response = await SettingsApi.addContact(payload);
    if (response.success) {
      showToast("success", "Contact Added");
      onSucessfullAdd();
    }
    onClose();
  };
  function validateEmail(input) {
    if (!input) {
      return { isValid: false, message: "Email is required" };
    }
    if (emailRegex.test(input)) {
      return { isValid: true };
    } else {
      return { isValid: false, message: "Invalid Email format" };
    }
  }
  function validateAndFormatPhoneNumber(input) {
    if (!input) {
      return { isValid: false, message: "Phone number is required" };
    }

    // Define regex patterns for accepted formats
    const regexPattern1 = /^\d{3}-\d{3}-\d{4}$/; // Matches 123-456-7890
    const regexPattern2 = /^\(\d{3}\) \d{3}-\d{4}$/; // Matches (123) 456-7890

    if (regexPattern1.test(input) || regexPattern2.test(input)) {
      // If the input matches one of the accepted formats, return it as valid
      return { isValid: true, formattedNumber: input };
    } else {
      // If the input doesn't match, format it to 123-456-7890
      const cleanedInput = input.replace(/\D/g, ""); // Remove all non-numeric characters
      if (cleanedInput.length === 10) {
        const formattedNumber = `${cleanedInput.slice(
          0,
          3
        )}-${cleanedInput.slice(3, 6)}-${cleanedInput.slice(6, 10)}`;
        return { isValid: true, formattedNumber };
      } else {
        return { isValid: false, message: "Invalid phone number format" };
      }
    }
  }

  return (
    <div className={`Modal ${contactModal ? "Show" : "hide"}`}>
      {state.loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="modal-header">
            <h4>Add Contact</h4>
            <span onClick={onClose}>
              <i className="fa fa-close close-primary-icon cursor-pointer"></i>
            </span>
          </div>

          <div className="modal-body">
            <GuiSelect
              label="Contact Type"
              name="contactType"
              value={state.contactType}
              onChange={(name, option) => handleChange(name, option.value)}
              options={state.typeOptions}
            />
            {state.contactType === 1 && (
              <span className="helper-text">
                Home phone numbers do not receive text messages
              </span>
            )}

            {state.contactType === 5 ? (
              <div className="address-field">
                <div className="address-field-row">
                  <GuiTextbox
                    label="Address"
                    name="address"
                    value={personAddress.address}
                    onChange={handleAddressChange}
                    error={
                      fieldErrors.address && (
                        <span>
                          <i className="fas fa-exclamation-circle"></i>{" "}
                          {fieldErrors.address}
                        </span>
                      )
                    }
                  />
                </div>
                <div className="address-field-row">
                  <GuiTextbox
                    label="City"
                    name="city"
                    value={personAddress.city}
                    onChange={handleAddressChange}
                    error={
                      fieldErrors.city && (
                        <span>
                          <i className="fas fa-exclamation-circle"></i>{" "}
                          {fieldErrors.city}
                        </span>
                      )
                    }
                  />
                </div>
                <div className="address-field-row">
                  <GuiSelect
                    label="State"
                    name="state"
                    value={personAddress.state}
                    onChange={(name, option) =>
                      handleAddressChange(name, option.value)
                    }
                    options={states}
                    error={
                      fieldErrors.state && (
                        <span>
                          <i className="fas fa-exclamation-circle"></i>{" "}
                          {fieldErrors.state}
                        </span>
                      )
                    }
                  />
                </div>
                <div className="address-field-row">
                  <GuiTextbox
                    label="Zip"
                    name="zip"
                    value={personAddress.zip}
                    zipPattern
                    onChange={handleAddressChange}
                    error={
                      fieldErrors.zip && (
                        <span>
                          <i className="fas fa-exclamation-circle"></i>{" "}
                          {fieldErrors.zip}
                        </span>
                      )
                    }
                  />
                </div>
                <div className="address-field-row">
                  <GuiTextbox
                    label="Comment (Optional)"
                    name="comment"
                    value={personAddress.comment}
                    onChange={handleAddressChange}
                  />
                </div>
              </div>
            ) : (
              <div>
                {state.contactType === 4 ? (
                  <div className="contact-email">
                    {" "}
                    <GuiTextbox
                      label="Email"
                      name="email"
                      value={state.email}
                      onChange={handleChange}
                    />{" "}
                  </div>
                ) : (
                  <div className="phone-num-ext">
                    <GuiTextbox
                      label="Phone Number"
                      name="phoneNumber"
                      className="phone-number"
                      value={state.phoneNumber}
                      onChange={handleChange}
                    />

                    <GuiTextbox
                      label="Ext."
                      name="ext"
                      className="phone-ext"
                      value={state.ext}
                      onChange={handleChange}
                    />
                  </div>
                )}
                {state.contactType === 4 ? (
                  <div className="error-class">
                    {errors.email && (
                      <div className="error">{errors.email}</div>
                    )}
                  </div>
                ) : (
                  <div className="error-class">
                    {errors.phoneNumber && (
                      <div className="error">{errors.phoneNumber}</div>
                    )}
                  </div>
                )}

                <div className="preferred-language">
                  {state.contactType === 3 && (
                    <div className="contact-checkboxes">
                      <div className="checkbox-row">
                        <GuiCheckbox
                          label="Enable Text Messages"
                          name="enableTextMsg"
                          value={state.enableTextMsg}
                          onChange={handleChange}
                        />
                      </div>

                      {state.enableTextMsg && (
                        <div className="checkbox-row">
                          <GuiSelect
                            className="contactSelect"
                            label="Preferred Language"
                            name="textType"
                            value={state.textType}
                            onChange={(name, option) =>
                              handleChange(name, option.value)
                            }
                            options={state.textOptions}
                          />
                        </div>
                      )}
                      <span className="helper-text">
                        The language your incoming text messages will be in
                      </span>
                    </div>
                  )}
                  <div className="checkbox-row">
                    <GuiCheckbox
                      label="Enable Voice Messages"
                      name="enableVoiceMsg"
                      value={state.enableVoiceMsg}
                      onChange={handleChange}
                    />
                  </div>
                  {state.enableVoiceMsg && (
                    <GuiSelect
                      className="contactSelect"
                      label="Preferred Language"
                      name="voiceType"
                      value={state.voiceType}
                      onChange={(name, option) =>
                        handleChange(name, option.value)
                      }
                      options={state.voiceOptions}
                    />
                  )}
                  <span className="helper-text">
                    The language you will hear text-to-speech messages in
                  </span>
                </div>
                {state.contactType !== 4 && (
                  <div className="contact-status">
                    <span className="contact-status-header">
                      Status of Contact Method:
                    </span>
                    <div className="contact-status-body">
                      <GuiRadio
                        label="Valid"
                        name="isValid"
                        id={true}
                        value={state.isValid}
                        onChange={handleRadioChange}
                      />
                      <GuiRadio
                        label="Invalid"
                        name="isValid"
                        id={false}
                        value={state.isValid}
                        onChange={handleRadioChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="modal-footer">
            {/* <div
              className={`modal-footer-hr-divider-${
                state.contactType === 3 ? "cell" : "home"
              }`}
            ></div> */}
            <div className="modal-footer-hr-divider"></div>
            <div className="modal-footer-btns">
              <GuiButton className="cancel-btn" onClick={onClose}>
                Cancel
              </GuiButton>
              {state.contactType === 5 ? (
                <GuiButton
                  className="add-btn"
                  disabled={state.isAddDisabled}
                  onClick={onUpdateAddress}
                >
                  Add
                </GuiButton>
              ) : (
                <GuiButton
                  className="add-btn"
                  disabled={state.isAddDisabled}
                  onClick={onAddClick}
                >
                  Add
                </GuiButton>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddContactPopup;
