import { apiRequestWithChecks } from "./api";

export const settings = {
  savePhoneSettings: function (token, phoneCode, phonePin) {
    return apiRequestWithChecks(
      "POST",
      "/api/settings/phone",
      {
        Authorization: "Bearer " + token,
      },
      {
        PhoneCode: phoneCode,
        PhonePin: phonePin,
      }
    );
  },
  getContactDetails: function (token) {
    return apiRequestWithChecks("GET", "/api/contacts/list", {
      Authorization: "Bearer " + token,
    });
  },
  getVoiceDetails: function (token) {
    return apiRequestWithChecks("GET", "/api/voice/list", {
      Authorization: "Bearer " + token,
    });
  },
  saveSmsPreferences: function (token, smsEmail, isEmailChecked) {
    return apiRequestWithChecks(
      "POST",
      "/api/settings/sms",
      {
        Authorization: "Bearer " + token,
      },
      {
        SmsNotifyEmail: smsEmail,
        SmsEnableNotify: isEmailChecked,
      }
    );
  },
  savePersonAddress: function (token, reqData) {
    return apiRequestWithChecks(
      "POST",
      "/api/settings/address",
      {
        Authorization: "Bearer " + token,
      },
      reqData
    );
  },
  getPersonAddress: function (token) {
    return apiRequestWithChecks("GET", "/api/settings/getaddress", {
      Authorization: "Bearer " + token,
    });
  },
  getVoiceOptions: function (token) {
    return apiRequestWithChecks("GET", "/api/voice/voiceoptions", {
      Authorization: "Bearer " + token,
    });
  },
  updateVoice: function (token, payload) {
    return apiRequestWithChecks(
      "PUT",
      "/api/voice/update",
      {
        Authorization: "Bearer " + token,
      },
      payload
    );
  },
  getContactLanguage: function (token) {
    return apiRequestWithChecks("GET", "/api/contacts/languages", {
      Authorization: "Bearer " + token,
    });
  },
  addContact: function (token, payload) {
    return apiRequestWithChecks(
      "POST",
      "/api/contacts/add",
      {
        Authorization: "Bearer " + token,
      },
      payload
    );
  },
  updateContact: function (token, payload) {
    return apiRequestWithChecks(
      "PUT",
      "/api/contacts/update",
      {
        Authorization: "Bearer " + token,
      },
      payload
    );
  },
  deleteContact: function (token, contactId) {
    return apiRequestWithChecks(
      "DELETE",
      "/api/contacts/delete",
      {
        Authorization: "Bearer " + token,
      },
      contactId
    );
  },
  sampleFile: function (token, payload) {
    return apiRequestWithChecks(
      "POST",
      "/api/file/samplefile",
      {
        Authorization: "Bearer " + token,
      },
      payload
    );
  },
  getMessageHistory: function (token, alertType, sort) {
    return apiRequestWithChecks(
      "GET",
      "/api/messagehistory/list?alertType=" + alertType + "&sort=" + sort,
      {
        Authorization: "Bearer " + token,
      }
    );
  },
  getUserGroups: function (token) {
    return apiRequestWithChecks("GET", "/api/settings/getgroups", {
      Authorization: "Bearer " + token,
    });
  },
  addUserGroups: function (token, payload) {
    return apiRequestWithChecks(
      "POST",
      "/api/settings/group",
      {
        Authorization: "Bearer " + token,
      },
      payload
    );
  },
  deleteUserGroups: function (token, contactId) {
    return apiRequestWithChecks(
      "DELETE",
      "/api/settings/DeleteUserGroups",
      {
        Authorization: "Bearer " + token,
      },
      contactId
    );
  },
};
