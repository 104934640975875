import React, { useState } from "react";
import "../styles/recent-messages.scss";
import { GuiSelect } from "../components/global-ui/gui-select";
import { useUpdateState } from "../utilities/utilities";
import AudioPlayer from "../components/audio-player";
import { useOnFirstLoad } from "../utilities/hooks";
import user from "../actions/user-actions";
import ToggleBar from "./ToggleBar";
import CircularProgress from "../actions/CircularProgress";

export default function RecentMessages({
  isEditing,
  isGroupEditing,
  isProfilePicEdit,
  isAddModalOpen,
}) {
  const [loading, setLoading] = useState(true); // Loading state
  let [state, updateState] = useUpdateState(() => {
    return {
      messageType: 0,
      typeOptions: [
        { value: 0, label: "Show All" },
        { value: 1, label: "Voice" },
        { value: 2, label: "Email" },
        { value: 3, label: "SMS" },
      ],
      messages: [],
    };
  });
  const { messageType } = state;
  useOnFirstLoad(() => {
    getMyMessages(messageType);
  });

  async function getMyMessages(messageType) {
    let result = await user.getRecentMessages(messageType);
    if (result.success) {
      updateState({ messages: result.recentMessages });
      setLoading(false);
    }
  }
  const onMessageTypeChange = (name, value) => {
    setLoading(true);
    updateState({ [name]: value });
    getMyMessages(value);
  };
  const formattedDate = (orgDate) => {
    const originalDate = new Date(orgDate);

    const yesterday = () => {
      let d = new Date();
      d.setDate(d.getDate() - 1);
      return d;
    };
    const currentDate = new Date().toISOString().split("T")[0];
    const yesterdayDate = yesterday().toISOString().split("T")[0];
    const scheduledDate = orgDate.split("T")[0];
    const month = originalDate.getMonth() + 1;
    const date = originalDate.getDate();
    const year = originalDate.getFullYear();

    const formattedTime = originalDate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    if (scheduledDate === currentDate) {
      return `${formattedTime}`;
    } else if (scheduledDate === yesterdayDate) {
      return "Yesterday";
    } else {
      return `${month}/${date}/${year}`;
    }
  };

  const handleTabToggle = (tabState) => {
    console.log("Active Tab : ", tabState);
    // This tab functionality will be implemented when we implement message preview .
  };
  return (
    <>
      <div
        className={`messages-container ${
          (isEditing || isGroupEditing || isProfilePicEdit || isAddModalOpen) &&
          "disabled"
        }`}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div className="header">
              <span className="header-title">Recent Messages</span>
              <div className="filters">
                <ToggleBar onTabClick={handleTabToggle}></ToggleBar>
                <GuiSelect
                  className="messageType"
                  label="Message Type"
                  name="messageType"
                  value={state.messageType}
                  onChange={(name, option) =>
                    onMessageTypeChange(name, option.value)
                  }
                  options={state.typeOptions}
                />
              </div>
            </div>

            <div className="messages-list">
              {state.messages.map((msg) => (
                <div key={msg.id} className="message-item">
                  <div className="avatar">
                    {msg.avatar ? (
                      <img src={msg.avatar} alt={`${msg.senderName}`} />
                    ) : (
                      <div className="initials">
                        {msg.senderName
                          .split(" ")
                          .map((n) => n[0])
                          .join("")}
                      </div>
                    )}
                  </div>
                  <div className="message-content">
                    <div className="message-header">
                      <span className="message-header-name">
                        {msg.senderName}
                      </span>
                      {msg.audio ? (
                        <div className="voice-note">
                          <div className="message-preview">
                            <div className="message-preview-subject">
                              {msg.messageType} – {msg.subject}
                            </div>

                            <div className="message-preview-time">
                              {formattedDate(msg.dateTimeStamp)}
                            </div>
                          </div>
                          {/* <audio controls>
                        <source src={msg.audio} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio> */}
                          <AudioPlayer src="" autoPlay={false} />
                        </div>
                      ) : (
                        <div className="message-preview">
                          <div className="message-preview-subject">
                            {msg.messageType} – {msg.subject}
                          </div>

                          <div className="message-preview-time">
                            {formattedDate(msg.dateTimeStamp)}
                          </div>
                        </div>
                      )}
                    </div>
                    <span className="message-preview-content">
                      {msg.messageDescription}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="recent-message-divider"></div>
            <div className="go-to-message-btn">
              Go to Messages
              <i className=" go-to-message-icon far fa-chevron-right"></i>
            </div>
          </>
        )}
      </div>
    </>
  );
}
