import React, { useState } from "react";
import "../styles/group-membership.scss";
import { SettingsApi } from "../actions/settings-action";
import { useOnFirstLoad } from "../utilities/hooks";
import { GuiButton } from "../components/global-ui/gui-button";
import CircularProgress from "../actions/CircularProgress";

export default function GroupMembership({
  onEditClick,
  isEditing,
  isProfilePicEdit,
  isAddModalOpen,
}) {
  const [myGroupDetails, setMyGroupDetails] = useState([]);
  const [isGroupEdit, setGroupEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  useOnFirstLoad(() => {
    getMyGroups();
  });

  function getMyGroups() {
    SettingsApi.getUserGroups().then((result) => {
      setMyGroupDetails(result.groups);
      result.success && setLoading(false);
    });
  }
  function editGroup() {
    setGroupEdit(!isGroupEdit);
    onEditClick();
  }
  const onDelete = (groupId) => {
    console.log("Delete Clicked", groupId);
  };
  return (
    <div
      className={`my-groups-container ${
        (isEditing || isProfilePicEdit || isAddModalOpen) && "disabled"
      }`}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="header">
            <span className="header-title">My Groups</span>
            <div className="add-edit-btns">
              <GuiButton className="add-edit-contact" disabled={isGroupEdit}>
                <i className="fa fa-plus"></i> Add Group
              </GuiButton>
              <GuiButton className="add-edit-contact" onClick={editGroup}>
                {isGroupEdit ? (
                  <>
                    <i className="fa fa-close"></i> Cancel Edit
                  </>
                ) : (
                  <>
                    <i className="fa fa-pencil"></i> Edit
                  </>
                )}
              </GuiButton>
            </div>
          </div>

          <div className="groups-list">
            {myGroupDetails.map((item) => (
              <>
                <div className="abc-row" key={item.groupId}>
                  <div className="avatar my-groups-column col-1">
                    {item.avatar ? (
                      <img src={item.avatar} alt={`${item.groupName}`} />
                    ) : (
                      <div className="initials">
                        {item.groupName
                          .split(" ")
                          .map((n) => n[0])
                          .slice(0, 2)
                          .join("")}
                      </div>
                    )}
                  </div>
                  {/* </div> */}
                  <div className="my-groups-column col-2 my-groups-group-name">
                    {item.groupName}
                  </div>
                  <div className="my-groups-column col-3 my-groups-group-members">
                    {item.userGroupCount} Members
                  </div>
                  <div className="my-groups-column col-4">
                    {item.description}
                  </div>
                  {isGroupEdit && (
                    <div
                      className="my-groups-column col-5"
                      onClick={() => onDelete(item.groupId)}
                    >
                      <i className="fa fa-trash icon-primary"></i>
                    </div>
                  )}
                </div>
                <div className="my-groups-divider"></div>
              </>
            ))}
          </div>
          <div className="go-to-groups-btn">
            Go to Groups
            <i className=" go-to-groups-icon far fa-chevron-right"></i>
          </div>
        </>
      )}
    </div>
  );
}
