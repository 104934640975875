import { apiRequestWithChecks } from "./api";

export const user = {
  getUserInfo: function (token) {
    return apiRequestWithChecks("GET", "/api/user", {
      Authorization: "Bearer " + token,
    });
  },

  getUserCount: function (token) {
    return apiRequestWithChecks("GET", "/api/user/count", {
      Authorization: "Bearer " + token,
    });
  },

  getUserTypes: function (token) {
    return apiRequestWithChecks("GET", "/api/user/types", {
      Authorization: "Bearer " + token,
    });
  },

  getUserProfile: function (token) {
    return apiRequestWithChecks("GET", "api/user/GetUserProfile", {
      Authorization: "Bearer " + token,
    });
  },
  getRecentMessages: function (token, alertType) {
    return apiRequestWithChecks(
      "GET",
      "api/dashboard/GetRecentMessages?alertType=" + alertType,
      {
        Authorization: "Bearer " + token,
      }
    );
  },
  getSpecificUserDetails: function (token) {
    return apiRequestWithChecks(
      "GET",
      "api/user/GetSpecificUserProfileDetail",
      {
        Authorization: "Bearer " + token,
      }
    );
  },
  updateUserProfile: function (token, payload) {
    return apiRequestWithChecks(
      "PUT",
      "/api/user/update",
      {
        Authorization: "Bearer " + token,
      },
      payload
    );
  },
};
